.container{
    display: flex;
    width: 100vw;
}

.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9px;
    width: 100%;
    margin-top: 20px;
}

.text{
    color: #72e478;
    font-size: 48px;
    font-weight: 700;
}

.underline{
    width: 40px;
    height: 4px;
    background: #72e478;
    border-radius: 9px;
}

.inputs{
margin-top: 35px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input{
    display: flex;
    align-items: center;
    margin: auto;
    width: 90%;
    max-width: 480px;
    height: 60px;
    background: #eaeaea;
    border-radius: 6px;
}

.input img{
    margin: 0px 15px;
}

.input input{
    height: 40px;
    width: calc(100% - 50px);
    background: transparent;
    border: none;
    outline: none;
    color: #797979;
    font-size: 16px;
}

.submit-container{
    display: flex;
    justify-content: center; /* Center the buttons */
    gap: 20px;
    margin: 20px auto;
}

.submit{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px; /* Adjust width for mobile */
    height: 50px;
    color: #fff;
    background: #72e478;
    border-radius: 25px;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
}

.gray{
    background: #EAEAEA;
    color: #676767;
}

@media (min-width: 576px) {

    .header .text{
        font-size: 48px;
    }

    .underline{
        width: 61px;
        height: 6px;
    }

    .inputs{
        margin-top: 55px;
    }

    .input{
        height: 80px;
    }

    .input input{
        height: 50px;
        width: 400px;
    }

    .submit-container{
        flex-direction: row;
        justify-content: center; /* Center the buttons */
    }

    .submit{
        width: 220px;
    }
}

.center{
    display: flex;
    justify-content: center;
    align-items: center;
}