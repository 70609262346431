.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
  }

  header.row {
    display: flex;
    height: calc(8vh);
    position: fixed;
    top: 0;
    width: 100%; 
    background-color: #72e478;
    border: 1px solid #58af5c;
    font-size: 18px;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
  }
  
  footer.row {
    display: flex;
    flex-direction: row;
    height: calc(8vh);
    position: fixed;
    width: 100%; 
    background-color: #72e478;
    border: 1px solid #58af5c;
    font-size: 13px;
    font-weight: 700;
    align-items: center;
    bottom: 0;

  }

  /* Style the selection components */
footer.row .selection {
  flex: 1; 
  max-width: calc(100% / 3);
  min-height: 100%;
  color: #000000;
  background-color: #72e478;
  cursor: pointer; /* Change cursor on hover */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


  

  